import React from 'react';
import PropTypes from 'prop-types';
import CustomHooks from '../../helpers/CustomHooks';
import QueryString from '../../helpers/QueryString';
import Header from '../shared/header/Header';
import CommonElements from '../shared/commonElements/CommonElements';
import BoxStructure from '../shared/boxStructure/BoxStructure';
import { updateInformation } from '../../redux/actions/designer'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const GalleryImagesPage = props => {

	const { t } = useTranslation();
	
	const mobileLandscape = CustomHooks.useMedia("(max-width: 1250px)");
	
	const settings = require(process.env.REACT_APP_SETTING_FILE_LOCATION);
	
	let selectedGallery = null;
	const queryParameters = window.top.location.href.split('?');
	if (typeof queryParameters[1] !== 'undefined') {
		let parameters = queryParameters[1].split('&');
		selectedGallery = QueryString.getDetailsFromParameters('gallery', parameters);
		localStorage.setItem('gallery', selectedGallery);
	}
	else {
		selectedGallery = localStorage.getItem('gallery');
	}

	const settings_imageDirs = settings.aamdemocentre.galleryPage.galleries[selectedGallery].imageDirs;
	let selectedCategory = null;
	let galleryList = [];
	let allImagesList = []; //To pass combined images' data for BoxStructure component

	const createImagesArray = (category, imgPath, categoryObj) => {
		category = category ? category : selectedGallery;
		imgPath = imgPath ? imgPath : selectedGallery;
		categoryObj = categoryObj ? categoryObj : null;

		let context = require.context('../../assets/galleryImages/', true, /\.(png|jpe?g|svg)$/);
		context.keys().map((key) => {
			const subDirPath = key.slice(2, key.lastIndexOf('/'));
			if(subDirPath === imgPath) {
				let obj = {};
				obj.keyName = category.toLowerCase();
				obj.title = key.slice(key.lastIndexOf('/')+1, key.length);
				obj.imgPath = context(key);
				if(selectedGallery.toLowerCase() === 'standard') {
					obj.galleryName = selectedGallery.toLowerCase();
				}

				if(categoryObj) {
					categoryObj[category].push(obj);
				}

				allImagesList.push(obj);
			}
		});

		if(categoryObj) {
			galleryList.push(categoryObj);
		}
	}
	
	if(settings_imageDirs.length != 0) {
		selectedCategory = settings_imageDirs[0].toLowerCase();
		settings_imageDirs.forEach((category, i) => {
			let categoryObj = {};
			categoryObj[category] = [];
			const imgPath = selectedGallery+'/'+category;
			createImagesArray(category, imgPath, categoryObj);
		});
	}
	else {
		selectedCategory = selectedGallery;
		createImagesArray();		
	}

	const pageTitle = t(settings.aamdemocentre.landingPage.galleries.guidelines[1].label);

	const handleNavigation = (e, nextPage, prevRouteFlag) => {
		e.preventDefault();
		// const activeStep = parseInt(e.currentTarget.id);

		if(nextPage != undefined) {
			if (prevRouteFlag === 'prev') {
				nextPage = '/gallery';
			}		
			else {
				// props.info.gallery = galleryList[activeStep].keyName;
				// updateInformation(props.info);
				// localStorage.setItem("gallery", galleryList[activeStep].keyName);
				// nextPage += '?gallery='+galleryList[activeStep].keyName;
			}
			props.history.push(nextPage);
		}
	}

	const handleTabs = (e) => {
		e.preventDefault();

		/* Toggle 'active' on Tabs */
		document.querySelectorAll('.tabs-li').forEach(function(elem) {
			elem.children[0].classList.remove('active');
		});
		e.target.classList.add('active');

		/* Toggle Images as per selected Tab */
		document.querySelectorAll(".data-box").forEach(function(elem) {
			if(elem.getAttribute('data-category') === e.target.id) {
				elem.classList.add('visible');
			}
			else {
				elem.classList.remove('visible');
			}
		});
	}

	return (
		<div>
			{mobileLandscape ? null : <Header history={props.history} />}
			<div className="container-wrapper">
				<div className="main-content-container galleryImages-container">

					{/* This creates Page header, Progress Bar, Top & Bottom Navigations */}
					<CommonElements 
						pageTitle = {pageTitle}
						nextPage = '/images'
						topNavigation={true}
						handleNavigation = {handleNavigation}
					/>

					{/* Gallery tabs */}
					{/* if({galleryList}.length != 0){ */}
						<div className='maxWidth-container tab-wrapper'>
							<ul className='tabs-list'>
							{
								galleryList.map((category, i) => {
									const categoryName = Object.keys(category)[0].toLowerCase();
									return (
										<li key={i} id={categoryName} className='tabs-li'>
											<a 
												href='#' 
												id={categoryName}
												className={categoryName === selectedCategory ? 'active' : ''}
												onClick={(e) => handleTabs(e)}>
												{categoryName}
											</a>
										</li>
									)
								})
							}
							</ul>
						</div>
					{/* } */}

					{/* Gallery images */}
					<div className='maxWidth-container images-wrapper'>
						<BoxStructure 
							data={allImagesList}
							handleNavigation={handleNavigation}
							currentPage='images'
							activeImgCategory={selectedCategory}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}


const mapStateToProps = state => {
	return {
		info: state.productsInfo.productsData,
		...state
	}
}

GalleryImagesPage.propTypes = {
	history: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(GalleryImagesPage);