import React from 'react';
import PropTypes from 'prop-types';
import CustomHooks from '../../helpers/CustomHooks';
import QueryString from '../../helpers/QueryString';
import Header from '../shared/header/Header';
import CommonElements from '../shared/commonElements/CommonElements';
import BoxStructure from '../shared/boxStructure/BoxStructure';
import Otsp from './otsp';
import { updateInformation } from '../../redux/actions/designer';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import progressBarHorizontalImage from '../../assets/progressBar/progressBarStep2_horizontal.svg';

const ExperiencePage = props => {
	const { t, i18n } = useTranslation();

	const mobileLandscape = CustomHooks.useMedia("(max-width: 1250px)");
	
	const settings = require(process.env.REACT_APP_SETTING_FILE_LOCATION);
	const configs = require(process.env.REACT_APP_CONFIG_FILE_LOCATION);

	let selectedFeature = null;
	const queryParameters = window.top.location.href.split('?');
	if (typeof queryParameters[1] !== 'undefined') {
		let parameters = queryParameters[1].split('&');
		selectedFeature = QueryString.getDetailsFromParameters('feature', parameters);
		localStorage.setItem('feature', selectedFeature);
	}
	else {
		selectedFeature = localStorage.getItem('feature');
	}

	const availableExperiences = configs.aamdemocentre.sequences.features[selectedFeature];
	const config_experiences = configs.aamdemocentre.experiences;
	const settings_experiences = settings.aamdemocentre.experiencePage.experiences;
	
	const isOTSP = selectedFeature.toLowerCase() === 'otsp' ? true : false;
	const designerList = availableExperiences.map((experience, i) => {
		if(config_experiences[experience] != undefined && settings_experiences[experience] != undefined) {
			return {
				keyName: experience,
				pageTitle: t(settings.aamdemocentre.landingPage.designer.guidelines[1].label),
				id: experience.id,
				title: t(settings_experiences[experience].title),
				desc: t(settings_experiences[experience].shortDescription),
				imgPath: require("../../assets/experience/" + settings_experiences[experience].image),
				handoverkey: config_experiences[experience].handoverkey,
				imagedeliverykey: config_experiences[experience].imagedeliverykey,
				skinName: config_experiences[experience].skin
			};
		}
		else {
			console.log('No records available for ' + experience);
		}
	});

	const pageTitle = t(settings.aamdemocentre.landingPage.designer.guidelines[1].label);

	const handleNavigation = (e, nextPage, prevRouteFlag) => {
		e.preventDefault();
		const activeStep = parseInt(e.currentTarget.parentElement.id);

		if (prevRouteFlag === 'prev') {
			nextPage = '/feature';
		}
		else if (nextPage === '/moreinformationexperience' || nextPage === '/designer') {
			// const obj = {
			// 	experience: designerList[activeStep].keyName,
			// 	handoverkey: designerList[activeStep].handoverkey,
			// 	imagedeliverykey: designerList[activeStep].imagedeliverykey
			// }
			// props.dispatch(updateInformation(obj));
			localStorage.setItem('experience', designerList[activeStep].keyName);
			localStorage.setItem('handoverkey', designerList[activeStep].handoverkey);
			localStorage.setItem('imagedeliverykey', designerList[activeStep].imagedeliverykey);

			/* we dont want our selected experience's key name from config file to be visible in our URL,
			hence we are splitting the experience key name to get just the designer name to show in the URL */
			const experience_url_param = designerList[activeStep].keyName.split('_')[1];
			nextPage += '?feature='+selectedFeature+'&experience='+experience_url_param;
		}
		props.history.push(nextPage);
	}

	return (
		<div>
			{mobileLandscape ? null : <Header history={props.history} />}
			<div className="container-wrapper">
				<div className="main-content-container experience-container">

					{/* This creates Page header, Progress Bar, Top & Bottom Navigations */}
					<CommonElements 
						pageTitle = {pageTitle}
						nextPage='/designer'
						progressBar = {true}
						progressBarImage = {progressBarHorizontalImage}
						progressBarFeatureImage={true}
						topNavigation={true}
						handleNavigation = {handleNavigation}
					/>

					{/* Experiences */}
					<div className='maxWidth-container exp-container'>
						<BoxStructure 
							data={designerList}
							handleNavigation={handleNavigation}
							nextPage='/designer'
							currentPage='experience'
						/>
					</div>
					
				</div>
			</div>
			{isOTSP ? <Otsp /> : ""}
		</div>
	);
}

const mapStateToProps = state => {
	return {
		...state
	}
}

ExperiencePage.propTypes = {
	history: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(ExperiencePage);