import React from 'react';
import PropTypes from 'prop-types';
import CustomHooks from '../../helpers/CustomHooks';
import QueryString from '../../helpers/QueryString';
import Header from '../shared/header/Header';
import CommonElements from '../shared/commonElements/CommonElements';
import { useTranslation } from 'react-i18next';

const MoreInformationFeaturePage = props => {
	const { t, i18n } = useTranslation();
	
	const mobileLandscape = CustomHooks.useMedia("(max-width: 1250px)")

	const settings = require(process.env.REACT_APP_SETTING_FILE_LOCATION);

	let selectedFeature = null;
	const queryParameters = window.top.location.href.split('?');
	if (typeof queryParameters[1] !== 'undefined') {
		let parameters = queryParameters[1].split('&');
		selectedFeature = QueryString.getDetailsFromParameters('feature', parameters);
		localStorage.setItem('feature', selectedFeature);
	}
	else {
		selectedFeature = localStorage.getItem('feature');
	}

	const featureInfo = settings.aamdemocentre.featurePage.features[selectedFeature].moreInfo;

	const handleNavigation = (e, nextPage, prevRouteFlag) => {
		e.preventDefault();
		if (prevRouteFlag === 'prev') {
			nextPage = '/feature';
		}
		nextPage += nextPage === '/feature' ? '' : '?feature='+selectedFeature;
		props.history.push(nextPage)
	}

	return (
		<div>
			{mobileLandscape ? null : <Header history={props.history} />}
			<div className="container-wrapper">
				<div className="main-content-container more-info-container">

					{/* This creates Page header, Progress Bar, Top & Bottom Navigations */}
					<CommonElements 
						subHeading = {featureInfo.title}
						shortDesc = {featureInfo.shortDescription}
						nextPage='/experience'
						bottomNavigation={true}
						handleNavigation = {handleNavigation}
					/>

					<div className="maxWidth-container more-info-content">
						<div className="image-sec"><img className="more-info-image" src={require('../../assets/moreInformationFeature/' + featureInfo.image)} alt='' /></div>
						<div className="desc-sec"><p className="more-info-image-desc" dangerouslySetInnerHTML={ { __html: featureInfo.longDescription } }></p></div>
					</div>
				</div>
			</div>
		</div>
	)
}

MoreInformationFeaturePage.propTypes = {
	history: PropTypes.object.isRequired
}

export default MoreInformationFeaturePage;